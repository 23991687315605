const brandingConfig = {
  branding: {
    colors: {
      primary: '#491e35',
      secondary: '#24384d',
      signal: '#499f9f',
    },
    qrCode: {
      style: 'dots',
      eyeRadius: 10,
      logoImage: `https://res.cloudinary.com/${process.env.NEXT_PUBLIC_CLOUDINARY_THEME_CLOUD_NAME}/image/upload/v1/themes/${process.env.NEXT_PUBLIC_THEME}/favicon/android-chrome-256x256.png`,
    },
  },
}

export default brandingConfig
