const googleMapConfig = {
  googleMap: {
    marker: {
      icon: null,
    },
    polyline: {
      strokeColor: '#e61c3e',
    },
  },
}

export default googleMapConfig
